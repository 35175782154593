<div class="mat-headline-4">{{ 'SETTINGS.MAIL.TITLE' | translate }}</div>

<form [formGroup]="userEmailsForm">
    <div class="mat-field-container py-1">
        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{ 'SETTINGS.MAIL.NEW-MAIL' | translate }}</mat-label>
            <input
                matInput
                type="email"
                formControlName="newEmail" />
        </mat-form-field>
    </div>

    <div class="mat-field-container py-1">
        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{
                'SETTINGS.MAIL.CONFIRM-NEW-MAIL' | translate
            }}</mat-label>
            <input
                matInput
                type="email"
                formControlName="newEmailRepeat" />
            <mat-error *ngIf="f.newEmailRepeat.errors?.confirmedValidator">{{
                'SETTINGS.MAIL.NO-MATCH' | translate
            }}</mat-error>
        </mat-form-field>
    </div>

    <button
        (click)="openCodeVerification()"
        [disabled]="
            userEmailsForm.invalid ||
            userEmailsForm.controls.newEmail.value !==
                userEmailsForm.controls.newEmailRepeat.value
        "
        mat-raised-button
        color="primary"
        type="submit"
        class="tw-w-80">
        {{ 'SETTINGS.MAIL.CONTINUE' | translate }}
    </button>

    <mat-error
        *ngIf="
            userEmailsForm.valid &&
            userEmailsForm.controls.newEmail.value !==
                userEmailsForm.controls.newEmailRepeat.value
        ">
        {{ 'SETTINGS.MAIL.ERROR-MAIL-MISMATCH' | translate }}
    </mat-error>
</form>
