import { Component, HostListener } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { ToastService } from '@app/core/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'api/services';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mail-verification-dialog',
    templateUrl: './mail-verification-dialog.component.html',
    styleUrls: ['./mail-verification-dialog.component.scss'],
    standalone: false,
})
export class MailVerificationDialogComponent {
    wrongCodeError = false;
    showSuccess = false;

    oneTimeCodesForm = new UntypedFormGroup({
        oldMailCode: new UntypedFormControl('', [Validators.required]),
        newMailCode: new UntypedFormControl('', [Validators.required]),
    });

    constructor(
        private usersService: UsersService,
        private sessionService: SessionService,
        private toastr: ToastService,
        private translateService: TranslateService
    ) {}

    @HostListener('window:keydown.enter')
    async completeEmailChange() {
        if (this.oneTimeCodesForm.invalid) {
            this.oneTimeCodesForm.markAllAsTouched();
            return;
        }

        const oldMailCode = this.oneTimeCodesForm.value['oldMailCode'].trim();
        const newMailCode = this.oneTimeCodesForm.value['newMailCode'].trim();

        try {
            await lastValueFrom(
                this.usersService.completeChangeEmailAddress({
                    body: {
                        oldEmailAddress2FaCode: oldMailCode,
                        newEmailAddress2FaCode: newMailCode,
                    },
                })
            );
            this.toastr.success(
                this.translateService.instant('VERIFY-MAIL-DIALOG.SUCCESS')
            );
            this.sessionService.logout();
        } catch (e) {
            this.wrongCodeError = true;
        }
    }
}
