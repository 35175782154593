<div>
    <div class="mat-headline-4">{{ 'SETTINGS.PROFILE.TITLE' | translate }}</div>

    <div class="tw-flex tw-flex-col">
        <mat-form-field
            *ngIf="user"
            disabled
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{ 'SETTINGS.PROFILE.ID' | translate }}</mat-label>
            <input
                disabled
                matInput
                [(ngModel)]="user['_key']" />
        </mat-form-field>

        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{ 'SETTINGS.PROFILE.USERNAME' | translate }}</mat-label>
            <input
                matInput
                [(ngModel)]="fullName" />
        </mat-form-field>
    </div>

    <div>
        <button
            [class.spinner]="loading"
            (click)="saveChanges()"
            [disabled]="loading || !usernameHasChanges"
            mat-raised-button
            color="primary"
            class="tw-w-80">
            {{ 'SETTINGS.PROFILE.SAVE' | translate }}
        </button>
    </div>
</div>
