import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MaintenanceService } from '@app/shared/services/maintenance.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MaintenanceGuard implements CanActivate {
    private maintenanceMode = false;
    private destroy$ = new Subject<void>();
    private previousUrl: string = '/';

    constructor(
        private router: Router,
        private maintenanceService: MaintenanceService
    ) {
        // Store the current URL before maintenance redirects
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                if (event.constructor.name === "NavigationEnd" && !this.maintenanceMode) {
                    if (!this.router.url.includes('/maintenance')) {
                        this.previousUrl = this.router.url;
                    }
                }
            });

        // Subscribe to maintenance mode changes
        this.maintenanceService.getMaintenanceMode()
            .pipe(takeUntil(this.destroy$))
            .subscribe(maintenance => {
                this.maintenanceMode = maintenance;
                if (maintenance) {
                    this.router.navigate(['/maintenance']);
                } else if (this.router.url.includes('/maintenance')) {
                    // When maintenance mode is disabled and we're on maintenance page,
                    // return to previous page or home
                    this.router.navigate([this.previousUrl || '/']);
                }
            });
    }

    canActivate(): Observable<boolean> | boolean {
        // If already in maintenance mode, redirect immediately
        if (this.maintenanceMode) {
            this.router.navigate(['/maintenance']);
            return false;
        }

        // Return an observable that maps the maintenance mode to a boolean
        return this.maintenanceService.getMaintenanceMode()
            .pipe(
                map(maintenance => {
                    if (maintenance) {
                        this.router.navigate(['/maintenance']);
                        return false;
                    }
                    return true;
                })
            );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}