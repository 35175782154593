<div
    *ngIf="!showSuccess"
    class="tw-overflow-hidden tw-p-5">
    <div class="tw-flex tw-justify-between">
        <h1 mat-dialog-title>
            {{ 'VERIFY-PASSWORD-DIALOG.TITLE' | translate }}
        </h1>

        <button
            mat-icon-button
            mat-dialog-close>
            <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <p class="tw-w-96">{{ 'VERIFY-PASSWORD-DIALOG.TEXT' | translate }}</p>

        <form [formGroup]="oneTimeCodesForm">
            <mat-form-field
                appearance="fill"
                class="tw-mt-6 tw-mb-0">
                <mat-label>{{
                    'VERIFY-PASSWORD-DIALOG.PLACEHOLDER' | translate
                }}</mat-label>
                <input
                    formControlName="code"
                    matInput />
            </mat-form-field>
        </form>

        <div
            *ngIf="wrongCodeError"
            class="tw-text-red-500">
            {{ 'VERIFY-PASSWORD-DIALOG.WRONG-CODE' | translate }}
        </div>
    </div>
    <div
        mat-dialog-actions
        align="end">
        <button
            mat-button
            mat-dialog-close>
            {{ 'VERIFY-PASSWORD-DIALOG.CANCEL' | translate }}
        </button>
        <button
            [disabled]="oneTimeCodesForm.invalid"
            mat-raised-button
            color="primary"
            (click)="completePasswordChange()">
            {{ 'VERIFY-PASSWORD-DIALOG.ACTION' | translate }}
        </button>
    </div>
</div>

<!--
<div *ngIf="showSuccess" class="tw-overflow-hidden tw-p-5 tw-text-center">
  <div mat-dialog-content>
    <p>{{ 'VERIFY-PASSWORD-DIALOG.SUCCESS' | translate }}</p>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">{{ 'VERIFY-PASSWORD-DIALOG.CLOSE' | translate
      }}</button>
  </div>
</div>
-->
