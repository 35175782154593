<div class="mat-headline-4">{{ 'SETTINGS.PASSWORD.TITLE' | translate }}</div>

<form
    id="change-password-form"
    [formGroup]="changePasswordForm">
    <div class="tw-flex tw-flex-col">
        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{
                'SETTINGS.PASSWORD.OLD-PASSWORD' | translate
            }}</mat-label>
            <input
                [type]="hidePasswordOld ? 'password' : 'text'"
                id="old-pw-input"
                matInput
                formControlName="oldPassword" />
            <mat-error
                *ngIf="
                    formControls.oldPassword.invalid &&
                    formControls.oldPassword.errors?.['required']
                ">
                {{ 'SETTINGS.PASSWORD.ERROR-OLDPW-REQUIRED' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    formControls.oldPassword.invalid &&
                    formControls.oldPassword.errors?.['hasUpper']
                ">
                {{ 'SETTINGS.PASSWORD.ERROR-REQUIRES-UPPERCASE' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    formControls.oldPassword.invalid &&
                    formControls.oldPassword.errors?.['sameAsOld']
                ">
                {{ 'SETTINGS.PASSWORD.ERROR-SAMEASOLD' | translate }}
            </mat-error>
            <button
                matSuffix
                mat-icon-button
                (click)="
                    $event.preventDefault(); hidePasswordOld = !hidePasswordOld
                ">
                <mat-icon fontSet="material-symbols-outlined">
                    {{ hidePasswordOld ? 'visibility_off' : 'visibility' }}
                </mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{
                'SETTINGS.PASSWORD.NEW-PASSWORD' | translate
            }}</mat-label>
            <input
                [type]="hidePasswordNew ? 'password' : 'text'"
                id="new-pw-input"
                matInput
                formControlName="newPassword" />
            <mat-error
                *ngIf="
                    formControls.newPassword.invalid &&
                    formControls.newPassword.errors?.['required']
                ">
                {{ 'SETTINGS.PASSWORD.ERROR-NEWPW-REQUIRED' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    formControls.newPassword.invalid &&
                    formControls.newPassword.errors?.['hasUpper']
                ">
                {{ 'SETTINGS.PASSWORD.ERROR-REQUIRES-UPPERCASE' | translate }}
            </mat-error>
            <button
                matSuffix
                mat-icon-button
                (click)="
                    $event.preventDefault(); hidePasswordNew = !hidePasswordNew
                ">
                <mat-icon fontSet="material-symbols-outlined">
                    {{ hidePasswordNew ? 'visibility_off' : 'visibility' }}
                </mat-icon>
            </button>
        </mat-form-field>
        <app-password-check
            (onCorrect)="newPasswordValid = $event"
            [password]="
                changePasswordForm.get('newPassword')?.value || ''
            "></app-password-check>
    </div>

    <div class="tw-mt-5">
        <mat-form-field
            appearance="fill"
            class="tw-w-80">
            <mat-label>{{
                'SETTINGS.PASSWORD.CONFIRM-NEW-PASSWORD' | translate
            }}</mat-label>
            <input
                [type]="hidePasswordNewRepeat ? 'password' : 'text'"
                id="new-pw-repeat-input"
                matInput
                formControlName="newPasswordRepeat" />
            <mat-error *ngIf="formControls.newPasswordRepeat.invalid">
                {{ 'SETTINGS.PASSWORD.ERROR-PW-MISMATCH' | translate }}
            </mat-error>
            <button
                matSuffix
                mat-icon-button
                (click)="
                    $event.preventDefault();
                    hidePasswordNewRepeat = !hidePasswordNewRepeat
                ">
                <mat-icon fontSet="material-symbols-outlined">
                    {{
                        hidePasswordNewRepeat ? 'visibility_off' : 'visibility'
                    }}
                </mat-icon>
            </button>
        </mat-form-field>
    </div>

    <button
        class="tw-w-80 tw-mt-6"
        mat-raised-button
        color="primary"
        (click)="changePw()"
        [disabled]="
            !changePasswordForm.value['oldPassword'] ||
            !newPasswordValid ||
            changePasswordForm.invalid ||
            loading
        ">
        {{ 'SETTINGS.PASSWORD.CONFIRM-BUTTON' | translate }}
    </button>
</form>

<div class="tw-block tw-h-12"></div>
