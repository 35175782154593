import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { Session } from '@app/shared/models/authentication/session';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoginResponse } from 'api/models';

interface SidebarSegment {
    label: string;
    destination: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    standalone: false,
})
export class UserSettingsComponent {
    selectedSegment: number = 0;
    userSession: LoginResponse;

    constructor(
        private router: Router,
        private sessionService: SessionService
    ) {
        this.userSession = this.sessionService.getSession();
    }

    sidebarSegments: SidebarSegment[] = [
        {
            label: 'SETTINGS.PROFILE-SETTINGS',
            destination: '/settings',
        },
        {
            label: 'SETTINGS.CHANGE-MAIL',
            destination: '/settings/email',
        },
        {
            label: 'SETTINGS.CHANGE-PW',
            destination: '/settings/password',
        },
    ];

    changeSegment(segment: SidebarSegment) {
        this.router.navigate([segment.destination]);
    }

    closeSettings() {
        this.router.navigate(['/']);
    }
}
