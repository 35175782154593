import { Component, HostListener } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { ToastService } from '@app/core/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'api/services';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-password-verification-dialog',
    templateUrl: './password-verification-dialog.component.html',
    styleUrls: ['./password-verification-dialog.component.scss'],
    standalone: false,
})
export class PasswordVerificationDialogComponent {
    wrongCodeError = false;
    showSuccess = false;

    oneTimeCodesForm = new UntypedFormGroup({
        code: new UntypedFormControl('', [Validators.required]),
    });

    constructor(
        private toastr: ToastService,
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<PasswordVerificationDialogComponent>,
        private usersService: UsersService,
        private sessionService: SessionService
    ) {}

    @HostListener('window:keydown.enter')
    async completePasswordChange() {
        if (this.oneTimeCodesForm.invalid) {
            this.oneTimeCodesForm.markAllAsTouched();
            return;
        }

        const code = this.oneTimeCodesForm.value['code'].trim();

        try {
            await lastValueFrom(
                this.usersService.completeChangePassword({
                    body: {
                        twoFaCode: code,
                    },
                })
            );
            this.toastr.success(
                this.translateService.instant('VERIFY-PASSWORD-DIALOG.SUCCESS')
            );
            this.dialogRef.close(true);
            this.toastr.success(
                this.translateService.instant('VERIFY-PASSWORD-DIALOG.RELOGIN')
            );
        } catch (e) {
            this.toastr.error(
                this.translateService.instant(
                    'VERIFY-PASSWORD-DIALOG.WRONG-CODE'
                )
            );
            this.wrongCodeError = true;
        } finally {
            this.sessionService.logoutAfterPasswordChange();
        }
    }
}
