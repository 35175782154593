import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { RequestChangePasswordRequest } from 'api/models';
import { UsersService } from 'api/services';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { PasswordVerificationDialogComponent } from './password-verification-dialog/password-verification-dialog.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false,
})
export class ChangePasswordComponent implements OnInit {
    changePasswordForm: UntypedFormGroup;
    hidePasswordOld: boolean = true;
    hidePasswordNew: boolean = true;
    hidePasswordNewRepeat: boolean = true;
    newPasswordValid: boolean = false;
    loading: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private usersService: UsersService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.changePasswordForm = this.formBuilder.group(
            {
                oldPassword: ['', [Validators.required]],
                newPassword: ['', [Validators.required]],
                newPasswordRepeat: ['', Validators.required],
            },
            {
                validators: [this.checkNewPasswords, this.sameAsOldPassword],
                updateOn: 'change',
            }
        );
    }

    changePw(): void {
        if (this.changePasswordForm.invalid) {
            return;
        }

        this.openCodeVerification();
    }

    async openCodeVerification() {
        try {
            const changePwDto: RequestChangePasswordRequest = {
                oldPassword: this.changePasswordForm.value['oldPassword'],
                newPassword: this.changePasswordForm.value['newPassword'],
            };

            await lastValueFrom(
                this.usersService.requestChangePassword({
                    body: changePwDto,
                })
            );

            this.dialog
                .open(PasswordVerificationDialogComponent)
                .afterClosed()
                .subscribe((result: null | boolean) => {
                    if (result) {
                        this.changePasswordForm.reset();
                        this.changePasswordForm
                            .get('oldPassword')
                            ?.setErrors(null);
                        this.changePasswordForm
                            .get('newPassword')
                            ?.setErrors(null);
                        this.changePasswordForm
                            .get('newPasswordRepeat')
                            ?.setErrors(null);
                    }
                });
        } catch (e: any) {
            if (e.status === 401) {
                this.toastService.error(
                    this.translateService.instant('GLOBAL.ERROR.UNAUTHORIZED')
                );
            } else if (e.status === 400) {
                this.toastService.error(
                    this.translateService.instant('GLOBAL.ERROR.WRONG-OLDPW')
                );
            } else {
                this.toastService.error(
                    this.translateService.instant('ERRORS.GENERAL')
                );
            }
        }
    }

    get formControls(): { [p: string]: AbstractControl } {
        return this.changePasswordForm.controls;
    }

    sameAsOldPassword: ValidatorFn = (
        group: AbstractControl
    ): ValidationErrors | null => {
        const oldPasswordControl: AbstractControl | null =
            group.get('oldPassword');
        const newPassword: string = group.get('newPassword')?.value;
        const oldPassword: string = group.get('oldPassword')?.value;
        const sameAsOld: boolean = newPassword === oldPassword;
        if (sameAsOld) {
            oldPasswordControl?.setErrors({ sameAsOld: true });
        } else {
            oldPasswordControl?.setErrors(null);
        }

        return null;
    };

    checkNewPasswords: ValidatorFn = (
        group: AbstractControl
    ): ValidationErrors | null => {
        const newPasswordRepeatControl: AbstractControl | null =
            group.get('newPasswordRepeat');
        const newPassword: string = group.get('newPassword')?.value;
        const confirmPassword: string = group.get('newPasswordRepeat')?.value;
        const notSame = newPassword !== confirmPassword;

        if (notSame) {
            newPasswordRepeatControl?.setErrors({ notSame: true });
        } else {
            newPasswordRepeatControl?.setErrors(null);
        }

        return null;
    };
}
