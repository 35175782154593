import { Component, OnInit } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginResponse } from 'api/models';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UsersService } from 'api/services';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-change-username',
    templateUrl: './change-username.component.html',
    styleUrls: ['./change-username.component.scss'],
    standalone: false,
})
export class ChangeUsernameComponent implements OnInit {
    userSession: LoginResponse;

    fullName: string = '';
    user: LoginResponse;
    loading: boolean = false;

    constructor(
        private sessionService: SessionService,
        private usersService: UsersService,
        private toastr: ToastService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.userSession = this.sessionService.getSession();
        if (this.userSession) {
            this.fullName = this.userSession.fullName;
            this.user = this.userSession;
        }
    }

    get usernameHasChanges() {
        return this.userSession.fullName !== this.fullName;
    }

    async saveChanges() {
        this.loading = true;
        try {
            const user = await lastValueFrom(
                this.usersService.patchBasicUser({
                    body: {
                        fullName: this.fullName?.trim(),
                    },
                })
            );
            this.userSession.fullName = user.fullName?.trim();
            await this.sessionService.loadUserFromApi();
            this.toastr.success(
                this.translateService.instant('SETTINGS.PROFILE.CHANGED')
            );
        } catch (error) {
            this.toastr.error(this.translateService.instant('GENERAL-ERROR'));
        } finally {
            // Timeout for better UX
            setTimeout(() => {
                this.loading = false;
            }, 300);
        }
    }
}
