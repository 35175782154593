import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { MailVerificationDialogComponent } from './mail-verification-dialog/mail-verification-dialog.component';
import {
    confirmedEqualValidator,
    getNumberValidator,
    getUpperValidator,
} from '@app/shared/form-validators';
import { AuthenticationService, UsersService } from 'api/services';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss'],
    standalone: false,
})
export class ChangeEmailComponent implements OnInit {
    userEmailsForm: UntypedFormGroup;

    constructor(
        public dialog: MatDialog,
        public usersService: UsersService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.userEmailsForm = this.formBuilder.group(
            {
                newEmail: new UntypedFormControl('', [
                    Validators.required,
                    Validators.email,
                ]),
                newEmailRepeat: new UntypedFormControl('', [
                    Validators.required,
                    Validators.email,
                ]),
            },
            {
                validators: [
                    confirmedEqualValidator('newEmail', 'newEmailRepeat'),
                ],
            }
        );
    }

    async openCodeVerification() {
        try {
            await lastValueFrom(
                this.usersService.requestChangeEmailAddress({
                    body: {
                        emailAddress: this.userEmailsForm.value['newEmail'],
                    },
                })
            );

            const dialogRef = this.dialog.open(
                MailVerificationDialogComponent,
                {
                    disableClose: true,
                }
            );
            dialogRef.afterClosed().subscribe((result: null | boolean) => {
                if (result) this.userEmailsForm.reset();
            });
        } catch (e: any) {
            if (e.status === 401) {
                this.toastService.error(
                    this.translateService.instant('GLOBAL.ERROR.UNAUTHORIZED')
                );
            } else {
                this.toastService.error(
                    this.translateService.instant('ERRORS.GENERAL')
                );
            }
        }
    }

    resetForm() {
        this.userEmailsForm.reset();
        this.userEmailsForm.get('newEmail')?.setErrors(null);
        this.userEmailsForm.get('newEmailRepeat')?.setErrors(null);
    }

    //  getter for  access to form field
    get f() {
        return this.userEmailsForm.controls;
    }
}
