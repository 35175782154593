<div class="tw-grid tw-h-full tw-grid-cols-6">
    <!-- Sidebar -->
    <div class="settings-full-h sidebar tw-col-span-1 tw-pt-12">
        <div
            class="tw-center-center tw-flex tw-items-center tw-px-5 tw-text-xl">
            <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
            <span class="tw-mb-0 tw-pl-2">{{
                'SETTINGS.TITLE' | translate
            }}</span>
        </div>

        <div class="tw-mt-10">
            <div class="link-items tw-flex tw-flex-col tw-pl-0">
                <a
                    *ngFor="let segment of sidebarSegments"
                    [routerLink]="segment.destination"
                    [routerLinkActive]="'text-primary active'"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="tw-py-3 tw-px-5 tw-text-lg tw-no-underline">
                    {{ segment.label | translate }}
                </a>
                <a
                    *ngIf="userSession && !userSession.termsOfService.accepted"
                    [routerLink]="['/settings/tos']"
                    [routerLinkActive]="'text-primary active'"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="tw-py-3 tw-px-5 tw-text-lg tw-no-underline"
                    >{{ 'SETTINGS.SIDEBAR-LINKS.TOS' | translate }}</a
                >
            </div>
        </div>
    </div>

    <!-- Content -->
    <div
        class="settings-full-h tw-relative tw-col-span-5 tw-flex tw-px-20 tw-pt-24">
        <div class="tw-absolute tw-top-8 tw-right-8">
            <button
                class="text-primary"
                mat-flat-button
                (click)="closeSettings()"
                matTooltip="{{ 'SETTINGS.CLOSE' | translate }}">
                {{ 'OUTGOING-GOODS.NEW-BUNDLE.BUTTON.CLOSE' | translate }}
            </button>
        </div>

        <router-outlet></router-outlet>
    </div>
</div>
